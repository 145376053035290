:root {
	--bg-gray: #100e0e;
	--bg-gray-light: #e3e3e3;
	--accent-purple: #5f50a5;
	--accent-orange: #fa643c;
	--accent-pink: #f0b9c3;
	--accent-gray: #646464;
	--white: #ffffff;
	--black: #000000;
	--pico-font-family-sans-serif: "Montserrat", Helvetica, Arial,
		"Helvetica Neue", sans-serif;
	--pico-background-color: #fff;
	--pico-color: #fff;

	--size: clamp(10rem, 1rem + 30vmin, 20rem);
	--gap: calc(var(--size) / 4);
	--duration: 60s;
	--scroll-start: 0;
	--scroll-end: calc(-100% - var(--gap));
}

@font-face {
	font-family: "Montserrat";
	src: url("./font/montserrat.woff2") format("woff2");
}

@font-face {
	font-family: "MontserratBlack";
	src: url("./font/montserratblack.woff2") format("woff2");
}

h1,
h2,
h3,
h4,
h5,
h6 {
	--pico-font-family: "MontserratBlack", "Montserrat", Helvetica, Arial,
		"Helvetica Neue", sans-serif;
	--pico-color: var(--white);
	text-transform: uppercase;
	line-height: 1em;
}

#datenschutz h1, #impressum h1, #datenschutz h2, #impressum h2, #datenschutz h3, #impressum h3 {
	--pico-color: var(--black);
}

.pill {
	padding: 0.5em 1em;
	border-radius: 999px;
	text-transform: inherit;
	display: inline-block;
	margin: 0 0 -1em;
	font-family: var(--pico-font-family-sans-serif);
}

body {
	overflow-wrap: normal;
	min-width: 320px;
}

/* Colors ****************** */
.orange {
	background-color: var(--accent-orange);
}

.purple {
	background-color: var(--accent-purple);
}

.gray-light {
	background-color: var(--bg-gray-light);
}

.pink {
	background-color: var(--accent-pink);
}

.bg-white {
	background-color: var(--white);
}

.bg-black {
	background-color: var(--black);
}

.text-white {
	color: var(--white);
}

.text-purple {
	color: var(--accent-purple);
}

.text-orange {
	color: var(--accent-orange);
}

canvas {
	width: 100%;
	height: 100%;
}

#dl-post {
	margin: auto auto 1em;
}
#dimmer {
	position: fixed;
	inset: 0;
	background-color: rgba(0,0,0,0.2) ;
	border: 20px dashed var(--accent-purple);
	z-index: 200;
}
#drop-area {
	background-color: var(--white);
	text-align: center;
	border-radius: 0.5em;
	padding: 3em;
	cursor: pointer;
    cursor: grab;
}

#drop-area img {
	margin-bottom: 0.5em;
}

#upload-button {
	color: var(--accent-gray);
	font-size: 0.8em;
	margin: auto;
	cursor: pointer;
	text-align: center;
}

#input-file {
	display: none;
}
#logo-position-label {
	font-size: 1.5rem;
	font-weight: bold;
}
.flex {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 1.2rem;
}
.flex > div {
	min-width: 350px;
	flex: 1 1;
}

.canvas-wrapper {
	min-height: 350px;
	display: flex;
	flex-direction: column;
	gap: 0.5em;
	justify-content: center;
	align-items: center;
	margin-bottom: 1em;
}

.download-btn {
	display: inline-block;
	background-color: var(--accent-orange);
	color: var(--black);
	padding: 10px 20px;
	margin: auto;
	font-weight: bold;
	text-transform: uppercase;
	text-decoration: none;
	border: none;
}

#copy-btn {
	align-self: flex-end;
}

.canvas-container {
		--canvas-aspect-ratio: 1 / 1;
    margin: auto;
    max-width: min(350px, 100%);
    max-height: min(350px, 100%);
	  aspect-ratio: var(--canvas-aspect-ratio);
}
.reset-wrapper{
	text-align: right;
	margin: .4rem;
}
#reset {
	background-color: transparent;
}

#imageCanvas4 {
    border: 2px solid var(--accent-purple);
}
.image-container {
	justify-content: space-between;
}

.image-container > div {
	min-height: 200px;
}

/* Sections ****************** */
header {
	text-align: right;
}

header #logo_dihk {
	max-width: 230px;
	width: 40vw;
}

header a {
	display: flex;
	justify-content: center;
}

header #logo {
    padding: 1.5em 1.5em 0.5em;
    width: 80vw;
    max-width: 1050px;
    min-width: 200px;
}

header p {
	color: var(--accent-purple);
	text-align: center;
	margin: 0;
	font-size: 0.8em;
}

#intro h2 {
	padding: 1em;
	margin: 0;
	font-size: 2.2em;
	text-align: center;
}

@media (max-width: 768px) {
	#intro h2 {
		font-size: 1.8em;
	}
}

#intro p {
	color: var(--white);
	text-align: center;
	padding: 0 0.2em 0.7em;
	font-size: 0.7em;
}

#intro p a {
	color: var(--white);
	border-bottom: 1px solid var(--white);
	text-decoration: none;
}

#intro p a:hover {
	color: var(--accent-purple);
	border-bottom: 1px solid var(--accent-purple);
}

#quote {
	position: relative;
	display: flex;
  	flex-direction: column;
}

#quote h2 {
	margin: 0;
    font-size: 1.7em;
    text-align: center;
    padding: 1em 15vw
}

#quote p {
	text-align: center;
	font-family: "MontserratBlack", "Montserrat", Helvetica, Arial,
		"Helvetica Neue", sans-serif;
	color: var(--accent-purple);
	font-size: 0.7em;
	padding: 0 80px;
}

#quote::before {
	content: "“";
	color: var(--accent-purple);
	position: absolute;
	margin-top: -0.365em;
	font-size: 8em;
	font-family: "MontserratBlack", "Montserrat", Helvetica, Arial,
		"Helvetica Neue", sans-serif;
}

#quote::after {
	content: "„";
	color: var(--accent-purple);
	position: absolute;
	font-size: 8em;
	font-family: "MontserratBlack", "Montserrat", Helvetica, Arial,
		"Helvetica Neue", sans-serif;
	right: 0;
	bottom: 0;
	margin-bottom: -0.23em;
}

#description p {
	padding: 1em;
	--pico-color: var(--black);
	text-align: center;
	margin: 0;
}

#description h2 {
	padding: 1em 1em;
	text-align: center;
	font-size: 2em;
	margin: 0;
}

@media (max-width: 768px) {
	#description h2 {
		font-size: 1.8em;
	}
}

#description #dihk_27prozent {
	padding: 2em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 1em;
}

#description #dihk_27prozent img {
	max-width: 250px;
}

#logo {
    padding: 2em;
}


#share div div {
	--pico-color: var(--white);
	padding: clamp(1rem, 2vw, 2em)
}

#share div:nth-child(2) {
	background-image: url("../img/icons_social.png");
	background-repeat: no-repeat;
	background-position: 90% 10%;
	background-size: 30%;
}

@media (max-width: 768px) {
	#share div:nth-child(2) {
		background-size: 20%;
	}
}

#share h3 {
	font-size: 6em;
}

#share p {
	overflow-wrap: break-word;
}

#explanation {
	text-align: center;
	padding: 3em 5vw;
	--pico-color: var(--accent-purple);
}

#explanation h2 {
	margin-top: 0;
}

#explanation h5 {
	color: var(--accent-purple);
}

#explanation a {
	--pico-underline: var(--accent-orange);
}

#result_postinggenerator {
	background-color: var(--white);
	margin-bottom: 4em;
	padding: 1.5em;
}

#result_postinggenerator .grid > div {
	display: flex;
    flex-direction: column;
}

.download-button {
	display: inline-block;
    margin: 1em auto;
}

#result strong {
	font-family: "MontserratBlack", "Montserrat", Helvetica, Arial,
		"Helvetica Neue", sans-serif;
}

.rounded {
	border-radius: 1em;
}

#posting {
	padding: 1em;

	display: flex;
	flex-direction: column;
}

.position-selection {
	display: flex;
	justify-content: center;
	gap: 0.5em;
}

@media (max-width: 575px) {
	.position-selection {
		flex-direction: column;
    	align-items: center;
	}
}

#logos {
	background-color: white;
}

footer {
	display: flex;
	justify-content: center;
	background-color: var(--accent-purple);
	padding: 3em 1em;
	text-align: center;
}

footer nav li {
	--pico-nav-element-spacing-horizontal: 2rem;
}

footer a {
	--pico-color: var(--white);
}

footer a:hover {
	color: var(--white);
	--pico-underline: var(--white);
	transition: none;
	text-decoration: underline;
}

dialog h3 {
	--pico-color: var(--accent-purple);
}

button, .button {
    --pico-color: var(--white);
    --pico-background-color: var(--accent-orange);
    padding: 0.45em 1.2em;
    border: none;
    cursor: pointer;
    border-radius: 999px;
    font-size: 0.8em;
	font-size: clamp(12px, 1.2vw, 16px);
    text-decoration: none;
	line-height: 1.2em;
}

button.active {
	--pico-background-color: var(--accent-purple);
}

button:is(:hover,:active,:focus), .button:is(:hover,:active,:focus) {
    --pico-background-color: var(--accent-purple);
	--pico-color: var(--white);
}

.copied::after {
	content: "\2713";
	position: relative;
	padding: 0 0 0 0.3em;
}

.copy-failed::after {
	content: "leider nicht möglich";
	position: relative;
	padding: 0 0 0 0.3em;
}

.hidden {
	display: none;
}

@media screen and (max-width: 380px) {
    nav ul {
		flex-direction: column;
	}
  }




/* Logo marquee ******************************************** */

.marquee {
	display: flex;
	overflow: hidden;
	user-select: none;
	gap: var(--gap);
	mask-image: linear-gradient(
		var(--mask-direction, to right),
		hsl(0 0% 0% / 0),
		hsl(0 0% 0% / 1) 5%,
		hsl(0 0% 0% / 1) 95%,
		hsl(0 0% 0% / 0)
	);
}

.marquee__group {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: var(--gap);
	min-width: 100%;
	animation: scroll-x var(--duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
	.marquee__group {
		animation-play-state: paused;
	}
}

.marquee--reverse .marquee__group {
	animation-direction: reverse;
	animation-delay: -3s;
}

@keyframes scroll-x {
	from {
		transform: translateX(var(--scroll-start));
	}
	to {
		transform: translateX(var(--scroll-end));
	}
}

@keyframes scroll-y {
	from {
		transform: translateY(var(--scroll-start));
	}
	to {
		transform: translateY(var(--scroll-end));
	}
}

/* Element styles */
.marquee img {
	display: grid;
	place-items: center;
	width: var(--size);
	fill: var(--color-text);
	background: var(--color-bg-accent);
	padding: calc(var(--size) / 10);
	border-radius: 0.5rem;
}

/* Parent wrapper */
.wrapper {
	display: flex;
	flex-direction: column;
	/* gap: var(--gap); */
	margin: auto;
	max-width: 100vw;
}

@keyframes fade {
	to {
		opacity: 0;
		visibility: hidden;
	}
}
