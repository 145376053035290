:root {
  --bg-gray: #100e0e;
  --bg-gray-light: #e3e3e3;
  --accent-purple: #5f50a5;
  --accent-orange: #fa643c;
  --accent-pink: #f0b9c3;
  --accent-gray: #646464;
  --white: #fff;
  --black: #000;
  --pico-font-family-sans-serif: "Montserrat", Helvetica, Arial, "Helvetica Neue", sans-serif;
  --pico-background-color: #fff;
  --pico-color: #fff;
  --size: clamp(10rem, 1rem + 30vmin, 20rem);
  --gap: calc(var(--size) / 4);
  --duration: 60s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}

@font-face {
  font-family: Montserrat;
  src: url("montserrat.d1935678.woff2") format("woff2");
}

@font-face {
  font-family: MontserratBlack;
  src: url("montserratblack.ba4e2007.woff2") format("woff2");
}

h1, h2, h3, h4, h5, h6 {
  --pico-font-family: "MontserratBlack", "Montserrat", Helvetica, Arial, "Helvetica Neue", sans-serif;
  --pico-color: var(--white);
  text-transform: uppercase;
  line-height: 1em;
}

#datenschutz h1, #impressum h1, #datenschutz h2, #impressum h2, #datenschutz h3, #impressum h3 {
  --pico-color: var(--black);
}

.pill {
  text-transform: inherit;
  font-family: var(--pico-font-family-sans-serif);
  border-radius: 999px;
  margin: 0 0 -1em;
  padding: .5em 1em;
  display: inline-block;
}

body {
  overflow-wrap: normal;
  min-width: 320px;
}

.orange {
  background-color: var(--accent-orange);
}

.purple {
  background-color: var(--accent-purple);
}

.gray-light {
  background-color: var(--bg-gray-light);
}

.pink {
  background-color: var(--accent-pink);
}

.bg-white {
  background-color: var(--white);
}

.bg-black {
  background-color: var(--black);
}

.text-white {
  color: var(--white);
}

.text-purple {
  color: var(--accent-purple);
}

.text-orange {
  color: var(--accent-orange);
}

canvas {
  width: 100%;
  height: 100%;
}

#dl-post {
  margin: auto auto 1em;
}

#dimmer {
  border: 20px dashed var(--accent-purple);
  z-index: 200;
  background-color: #0003;
  position: fixed;
  inset: 0;
}

#drop-area {
  background-color: var(--white);
  text-align: center;
  cursor: pointer;
  cursor: grab;
  border-radius: .5em;
  padding: 3em;
}

#drop-area img {
  margin-bottom: .5em;
}

#upload-button {
  color: var(--accent-gray);
  cursor: pointer;
  text-align: center;
  margin: auto;
  font-size: .8em;
}

#input-file {
  display: none;
}

#logo-position-label {
  font-size: 1.5rem;
  font-weight: bold;
}

.flex {
  flex-flow: wrap;
  gap: 1.2rem;
  display: flex;
}

.flex > div {
  flex: 1;
  min-width: 350px;
}

.canvas-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5em;
  min-height: 350px;
  margin-bottom: 1em;
  display: flex;
}

.download-btn {
  background-color: var(--accent-orange);
  color: var(--black);
  text-transform: uppercase;
  border: none;
  margin: auto;
  padding: 10px 20px;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

#copy-btn {
  align-self: flex-end;
}

.canvas-container {
  --canvas-aspect-ratio: 1 / 1;
  aspect-ratio: var(--canvas-aspect-ratio);
  max-width: min(350px, 100%);
  max-height: min(350px, 100%);
  margin: auto;
}

.reset-wrapper {
  text-align: right;
  margin: .4rem;
}

#reset {
  background-color: #0000;
}

#imageCanvas4 {
  border: 2px solid var(--accent-purple);
}

.image-container {
  justify-content: space-between;
}

.image-container > div {
  min-height: 200px;
}

header {
  text-align: right;
}

header #logo_dihk {
  width: 40vw;
  max-width: 230px;
}

header a {
  justify-content: center;
  display: flex;
}

header #logo {
  width: 80vw;
  min-width: 200px;
  max-width: 1050px;
  padding: 1.5em 1.5em .5em;
}

header p {
  color: var(--accent-purple);
  text-align: center;
  margin: 0;
  font-size: .8em;
}

#intro h2 {
  text-align: center;
  margin: 0;
  padding: 1em;
  font-size: 2.2em;
}

@media (max-width: 768px) {
  #intro h2 {
    font-size: 1.8em;
  }
}

#intro p {
  color: var(--white);
  text-align: center;
  padding: 0 .2em .7em;
  font-size: .7em;
}

#intro p a {
  color: var(--white);
  border-bottom: 1px solid var(--white);
  text-decoration: none;
}

#intro p a:hover {
  color: var(--accent-purple);
  border-bottom: 1px solid var(--accent-purple);
}

#quote {
  flex-direction: column;
  display: flex;
  position: relative;
}

#quote h2 {
  text-align: center;
  margin: 0;
  padding: 1em 15vw;
  font-size: 1.7em;
}

#quote p {
  text-align: center;
  color: var(--accent-purple);
  padding: 0 80px;
  font-family: MontserratBlack, Montserrat, Helvetica, Arial, Helvetica Neue, sans-serif;
  font-size: .7em;
}

#quote:before {
  content: "“";
  color: var(--accent-purple);
  margin-top: -.365em;
  font-family: MontserratBlack, Montserrat, Helvetica, Arial, Helvetica Neue, sans-serif;
  font-size: 8em;
  position: absolute;
}

#quote:after {
  content: "„";
  color: var(--accent-purple);
  margin-bottom: -.23em;
  font-family: MontserratBlack, Montserrat, Helvetica, Arial, Helvetica Neue, sans-serif;
  font-size: 8em;
  position: absolute;
  bottom: 0;
  right: 0;
}

#description p {
  --pico-color: var(--black);
  text-align: center;
  margin: 0;
  padding: 1em;
}

#description h2 {
  text-align: center;
  margin: 0;
  padding: 1em;
  font-size: 2em;
}

@media (max-width: 768px) {
  #description h2 {
    font-size: 1.8em;
  }
}

#description #dihk_27prozent {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em 2em 1em;
  display: flex;
}

#description #dihk_27prozent img {
  max-width: 250px;
}

#logo {
  padding: 2em;
}

#share div div {
  --pico-color: var(--white);
  padding: clamp(1rem, 2vw, 2em);
}

#share div:nth-child(2) {
  background-image: url("icons_social.dcfcf7fb.png");
  background-position: 90% 10%;
  background-repeat: no-repeat;
  background-size: 30%;
}

@media (max-width: 768px) {
  #share div:nth-child(2) {
    background-size: 20%;
  }
}

#share h3 {
  font-size: 6em;
}

#share p {
  overflow-wrap: break-word;
}

#explanation {
  text-align: center;
  --pico-color: var(--accent-purple);
  padding: 3em 5vw;
}

#explanation h2 {
  margin-top: 0;
}

#explanation h5 {
  color: var(--accent-purple);
}

#explanation a {
  --pico-underline: var(--accent-orange);
}

#result_postinggenerator {
  background-color: var(--white);
  margin-bottom: 4em;
  padding: 1.5em;
}

#result_postinggenerator .grid > div {
  flex-direction: column;
  display: flex;
}

.download-button {
  margin: 1em auto;
  display: inline-block;
}

#result strong {
  font-family: MontserratBlack, Montserrat, Helvetica, Arial, Helvetica Neue, sans-serif;
}

.rounded {
  border-radius: 1em;
}

#posting {
  flex-direction: column;
  padding: 1em;
  display: flex;
}

.position-selection {
  justify-content: center;
  gap: .5em;
  display: flex;
}

@media (max-width: 575px) {
  .position-selection {
    flex-direction: column;
    align-items: center;
  }
}

#logos {
  background-color: #fff;
}

footer {
  background-color: var(--accent-purple);
  text-align: center;
  justify-content: center;
  padding: 3em 1em;
  display: flex;
}

footer nav li {
  --pico-nav-element-spacing-horizontal: 2rem;
}

footer a {
  --pico-color: var(--white);
}

footer a:hover {
  color: var(--white);
  --pico-underline: var(--white);
  text-decoration: underline;
  transition: none;
}

dialog h3 {
  --pico-color: var(--accent-purple);
}

button, .button {
  --pico-color: var(--white);
  --pico-background-color: var(--accent-orange);
  cursor: pointer;
  border: none;
  border-radius: 999px;
  padding: .45em 1.2em;
  font-size: clamp(12px, 1.2vw, 16px);
  line-height: 1.2em;
  text-decoration: none;
}

button.active {
  --pico-background-color: var(--accent-purple);
}

button:is(:hover, :active, :focus), .button:is(:hover, :active, :focus) {
  --pico-background-color: var(--accent-purple);
  --pico-color: var(--white);
}

.copied:after {
  content: "✓";
  padding: 0 0 0 .3em;
  position: relative;
}

.copy-failed:after {
  content: "leider nicht möglich";
  padding: 0 0 0 .3em;
  position: relative;
}

.hidden {
  display: none;
}

@media screen and (max-width: 380px) {
  nav ul {
    flex-direction: column;
  }
}

.marquee {
  -webkit-user-select: none;
  user-select: none;
  gap: var(--gap);
  -webkit-mask-image: linear-gradient(var(--mask-direction, to right), #0000, #000 5%, #000 95%, #0000);
  mask-image: linear-gradient(var(--mask-direction, to right), #0000, #000 5%, #000 95%, #0000);
  display: flex;
  overflow: hidden;
}

.marquee__group {
  justify-content: space-around;
  align-items: center;
  gap: var(--gap);
  animation: scroll-x var(--duration) linear infinite;
  flex-shrink: 0;
  min-width: 100%;
  display: flex;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee--reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: -3s;
}

@keyframes scroll-x {
  from {
    transform: translateX(var(--scroll-start));
  }

  to {
    transform: translateX(var(--scroll-end));
  }
}

@keyframes scroll-y {
  from {
    transform: translateY(var(--scroll-start));
  }

  to {
    transform: translateY(var(--scroll-end));
  }
}

.marquee img {
  width: var(--size);
  fill: var(--color-text);
  background: var(--color-bg-accent);
  padding: calc(var(--size) / 10);
  border-radius: .5rem;
  place-items: center;
  display: grid;
}

.wrapper {
  flex-direction: column;
  max-width: 100vw;
  margin: auto;
  display: flex;
}

@keyframes fade {
  to {
    opacity: 0;
    visibility: hidden;
  }
}

/*# sourceMappingURL=index.bc409e3c.css.map */
